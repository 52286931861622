<template>
    <a-layout class="DeviceManagementAdmin">
        <a-layout>
            <a-layout-header class="app-header">
                <div class="logo">
                    <a href="/"><img src="../assets/images/ic_logo_white.png" alt=""></a>
                </div>
                <a-menu
                        theme="dark"
                        mode="horizontal"
                        :defaultSelectedKeys="[this.$route.path]"
                        :style="{ lineHeight: '64px' }"
                >
                    <!--<a-menu-item key="/dashboard">
                        <router-link :to="{ path: '/dashboard' }" class="nav-link">
                            <a-icon type="dashboard" />
                            <span>Dashboard</span>
                        </router-link>

                    </a-menu-item>
                    <a-menu-item key="/chi-tiet-danh-muc-thiet-bi">
                        <router-link :to="{ path: '/chi-tiet-danh-muc-thiet-bi' }" class="nav-link">
                            <a-icon type="dashboard" />
                            <span>Danh mục thiết bị</span>
                        </router-link>
                        
                    </a-menu-item>-->

                    <a-sub-menu title="Thiết bị">
                        <a-menu-item key="/equipment/camera">
                            <router-link :to="{ path: '/equipment/camera' }" class="nav-link">
                                <span>Camera</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>

                    <a-sub-menu title="Hóa đơn">
                        <a-menu-item key="/bills/video">
                            <router-link :to="{ path: '/bills/video' }" class="nav-link">
                                <span>Video</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>

                    
                </a-menu>
                <div class="header-right">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item @click="logout" key="3"><a-icon type="user" />Đăng xuất</a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px">Xin chào <strong style="margin: 0 10px">Admin</strong> <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </div>
            </a-layout-header>
            <router-view></router-view>
        </a-layout>
    </a-layout>
</template>

<script>

    export default {
        name: "DeviceManagementAdmin",
        data() {
            return {
                collapsed: false
            };
        },
        methods: {
            logout: async () => {
                // eslint-disable-next-line no-console,no-undef
                await ssoLogout()
            },
        },
    };
</script>

<style lang="scss">
    .DeviceManagementAdmin {
        background-color: #31343d;

        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color .3s;
        }

        .app-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px;
        }

        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-actived {
            background-color: transparent;
        }

        .header-right{
            text-align: right;
            width: 100%;
        }

        .logo img {
            height: 58px;
            margin-bottom: 10px;
            margin-right: 30px;
        }
    }
</style>
